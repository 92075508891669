<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader tag="div">
          <CIcon name="cil-grid"/>
          Chi tiết nhóm quyền
        </CCardHeader>
        <CCardBody style="min-height: 300px" tag="div">
          <CForm>
            <CRow>
              <CCol lg="6">
                <CInput label="Tên nhóm" horizontal :value="detailItem.name" readonly/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CSelect label="Loại đơn vị" horizontal :value.sync="detailItem.loaiDonVi" disabled :options="optionsLoaiDonVi"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <div role="group" class="form-group form-row">
                  <CCol tag="label" sm="3" class="col-form-label">Chức năng</CCol>
                  <CCol sm="9">
                    <multiselect v-model="selectedPermissions" :options="optionsPermissions" :multiple="true"
                                 group-values="items"
                                 group-label="groupName" :group-select="true" track-by="label"
                                 label="label" placeholder="Chọn chức năng" selectLabel="Nhấn để chọn"
                                 selectGroupLabel="Nhấn để chọn theo nhóm" selectedLabel="Đã chọn"
                                 deselectLabel="Nhấn để xóa" deselectGroupLabel="Nhấn để xóa theo nhóm" disabled>
                      <span slot="noResult">Không tìm thấy.</span>
                    </multiselect>
                  </CCol>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CTextarea label="Mô tả" horizontal :value="detailItem.description" readonly/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <div role="group" class="form-group form-row">
                  <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                  <CCol sm="9" class="form-inline">
                    <CInputCheckbox label="Hoạt động" :checked="detailItem.status" disabled/>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CForm>
          <CElementCover v-if="isLoading"/>
        </CCardBody>
        <CCardFooter tag="div" class="text-right">
          <CButton color="secondary" class="mr-2" @click="back">
            <CIcon name="cil-arrow-left"/>
            Quay lại
          </CButton>
          <CButton color="primary" class="mr-2" @click="editItem">
            <CIcon name="cil-pencil"/>
            Sửa
          </CButton>
          <CButton color="danger" @click="isDeleteConfirmationModalOpen = true">
            <CIcon name="cil-x"/>
            Xóa
          </CButton>
        </CCardFooter>
      </CCard>
    </CCol>
    <DeleteConfirmationModal
      title="Xóa nhóm quyền"
      :show.sync="isDeleteConfirmationModalOpen"
      :is-deleting="isDeleting"
      :item="detailItem" @confirm-destroy="confirmDestroy"/>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  DELETE_APPLICATION_ROLE,
  GET_APPLICATION_ROLE
} from '@/store/modules/application-role/actionTypes'
import { enums } from '@/shared/enums'
import { applicationRoleService } from '@/services/application-role.service'

export default {
  name: 'ApplicationRoleDetail',
  data () {
    return {
      selectedPermissions: [],
      isDeleteConfirmationModalOpen: false,
      isLoading: false,
      optionsPermissions: [],
      optionsLoaiDonVi: enums.loaiDonVi
    }
  },
  computed: {
    ...mapGetters('applicationRole', {
      detailItem: 'detailItem',
      isFetchingSingle: 'isFetchingSingle',
      isDeleting: 'isDeleting',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('applicationRole', {
      getItem: GET_APPLICATION_ROLE,
      deleteItem: DELETE_APPLICATION_ROLE
    }),
    back () {
      this.$router.push({ path: '/nhom-quyen' })
    },
    editItem () {
      this.$router.push({ path: `/nhom-quyen/${this.detailItem.id}/cap-nhat` })
    },
    async confirmDestroy () {
      await this.deleteItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.$router.push({ path: '/nhom-quyen' })
      }
    }
  },
  async created () {
    this.isLoading = true
    await this.getItem(this.$route.params.id)
    this.optionsPermissions = await applicationRoleService.getPermissions(this.detailItem.loaiDonVi)
    if (this.detailItem && this.detailItem.permissions) {
      const permissions = this.detailItem.permissions.split(',')
      permissions.forEach(per => {
        this.optionsPermissions.forEach(p => {
          const item = p.items.find(x => x.value === per)
          if (item) {
            this.selectedPermissions.push(item)
          }
        })
      })
    }
    this.isLoading = false
  }
}
</script>

<style scoped>

</style>
