import appConfig from '@/shared/appConfig'
import jwtInterceptor from '@/shared/jwtInterceptor'

const BASE_ENDPOINT = `${appConfig.BASE_HOST}/api/ApplicationRole`

const getByLoaiDonVi = async (loaiDonVi) => {
  const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/options/${loaiDonVi}`).catch(() => {
    return []
  })
  if (response && response.data && !response.data.error) {
    return response.data.obj
  }
  return []
}

const getPermissions = async (loaiDonVi) => {
  const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/permissions/${loaiDonVi}`).catch(() => {
    return []
  })
  if (response && response.data && !response.data.error) {
    return response.data.obj
  }
  return []
}

export const applicationRoleService = {
  getByLoaiDonVi,
  getPermissions
}
